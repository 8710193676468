@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
@font-face {
  font-family: 'Roboto';
  font-weight: 100;
  src: url('../assets/fonts/Roboto/Roboto-Thin.woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('../assets/fonts/Roboto/Roboto-Light.woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../assets/fonts/Roboto/Roboto-Regular.woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../assets/fonts/Roboto/Roboto-Medium.woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 600;
  src: url('../assets/fonts/Roboto/Roboto-Bold.woff');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: url('../assets/fonts/Roboto/Roboto-Black.woff');
}

.input-no-rule {
  padding-bottom: 20px;
}

.q-footer.fixed-bottom {
  bottom: 0 !important;
}
.q-dialog.fullscreen {
  bottom: 0 !important;
  max-height: inherit !important;
  z-index: 9999;
}

a {
  text-decoration: none;
}

.text-transform-none {
  text-transform: none;
}

.btn-big {
  height: 67px;
  border-radius: 16px;
  .q-btn__wrapper {
    padding: 0;
  }
}

.box-info {
  border: 1px solid $primary;
  border-radius: 16px;
}

.card-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.card-footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.modal {
  &-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
  }
  &-wrapper {
    padding: 15px;
    width: 100%;
    margin: auto;
  }
  &-container {
    overflow: auto;
  }
  // &-container {
  //   width: 300px;
  //   margin: 0px auto;
  //   padding: 20px 30px;
  //   background-color: #fff;
  //   border-radius: 2px;
  //   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  //   transition: all 0.3s ease;
  //   font-family: Helvetica, Arial, sans-serif;
  // }
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.vm-dialog.vm--container {
  z-index: 2001;
}
.vm-dialog .vm--modal {
  background-color: transparent;
  padding: 15px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vm-fullscreen .vm--modal {
  width: 100% !important;
  height: 100% !important;
}

.vue-lb-container {
  z-index: 2001 !important;
}

.option-vehicle {
  .q-radio__inner {
    display: none;
  }
  .q-radio__label {
    padding: 8px;
    border-radius: 4px;
    background-color: #d5d5d596;
    font-size: 13px;
    font-weight: 500;
  }
  .q-radio__inner--truthy ~ .q-radio__label {
    background-color: #009688;
    color: #fff;
  }
}
